import React from "react"
import { Helmet } from "react-helmet"

import CssReset from "../theme/styles/cssReset"
import Header from "../components/molocules/header"
import FooterContainer from "../components/molocules/footer"
import Alert from "../components/elements/alert/alert"
import { Link } from "gatsby"
import ChatButton from "../components/elements/chatButton"

const Layout = ({
  children,
  isSubMenuOpen,
  openAccountLink,
  forceRootMenu,
}) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          maximum-scale="1"
        />
      </Helmet>
      <CssReset />
      <div id="main-content">
     <Alert type="Info" enabled>
           <b>Important Update: Branch Operations Due to Hurricane Milton –</b>{" "}
We are closely monitoring Hurricane Milton for any impact on our branch operations. For the safety of our customers and employees, our Jacksonville Office will close today, October 9th at 1:00pm, and will likely remain closed through Thursday.  We plan to reopen Friday, October 11th, weather permitting, but for the latest updates on branch availability, please check back here. You can always bank with us on our mobile app or at one of our ATMs.
Stay safe and thank you for your understanding!

  </Alert>
 {/*
      <Alert type="Info" enabled>
        <b>Fraud Alert:</b> TCB and other area banks have seen a huge uptick in
        phishing texts over the last 24 hours. If you receive a call or text
        message from someone claiming to be from The Claxton Bank asking to
        click on a link or provide any sensitive information, such as name,
        address, social security number, or digital banking username or
        password, please hang up immediately and call TCB 912-739-3322. WE WILL
        NEVER CALL YOU AND ASK FOR PERSONAL OR SENSITIVE INFORMATION. If you
        feel like you have inadvertently given out or shared your personal
        information, please contact us immediately. Thanks. – The Claxton Bank.
      </Alert>*/}

      <Header
        isSubMenuOpen={isSubMenuOpen}
        openAccountLink={openAccountLink}
        forceRootMenu={forceRootMenu}
      />
      {children}
      <ChatButton />
      <FooterContainer />
      </div>
    </>
  )
}

export default Layout
